import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Footer from '../components/Footer'
import Layout from '../Layout'
import SEO from '../SEO'
import { AboutPage } from '../util/types'

interface Props extends PageProps {
    data: {
        sanityAbout: AboutPage
    }
}

const About: React.FC<Props> = ({ data }) => {
    const {
        paragraphs,
        image_copyright,
        image,
        other_contributors,
        other_contributors_title,
        contributor1,
        contributor2,
        additional_note
    } = data.sanityAbout

    return (
        <div>
            <Layout currentPage="about">
                <SEO title="Was Wa(h)r - About" />
            </Layout>

            <div className="grid about">
                <div
                    className="about-image"
                    style={{ backgroundImage: image ? `url(${image.asset.url})` : '' }}
                >
                    <span>{image_copyright}</span>
                </div>

                <div className="about-paragraphs about-fullsize">
                    {paragraphs.map((p, i) => (
                        <p key={i}>
                            {p.replace('info@waswahr.de', '')}
                            {p.indexOf('info@waswahr.de') != -1 && (
                                <a href="mailto:info@waswahr.de">info@waswahr.de</a>
                            )}
                        </p>
                    ))}
                </div>

                <div className="about-contributor">
                    <a href={contributor1.url} target="_blank">
                        {contributor1.name}
                    </a>

                    <p className="about-caption">[{contributor1.contribution}]</p>
                    <p className="about-hide">{contributor1.bio}</p>
                </div>

                <div className="about-contributor">
                    <a href={contributor2.url} target="_blank">
                        {contributor2.name}
                    </a>
                    <p className="about-caption">[{contributor2.contribution}]</p>
                    <p className="about-hide">{contributor2.bio}</p>
                </div>

                <div className="about-fullsize">
                    <p>{other_contributors_title}</p>
                </div>

                <div className="about-fullsize">
                    {other_contributors.map((c, i) => (
                        <div key={i}>
                            <p>{c.names}</p>
                            <span>[{c.contribution}]</span>
                        </div>
                    ))}
                </div>

                <div className="about-fullsize">
                    <p>{additional_note}</p>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export const query = graphql`
    query {
        sanityAbout {
            paragraphs
            other_contributors_title
            image_copyright
            other_contributors {
                contribution
                names
            }
            image {
                asset {
                    url
                }
            }
            contributor2 {
                bio
                contribution
                name
                url
            }
            contributor1 {
                bio
                contribution
                name
                url
            }
            additional_note
        }
    }
`

export default About
